<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-text class="d-flex">
      <v-avatar rounded size="120" class="me-6">
        <v-img v-if="this.currentImage != null" :src="currentImage"></v-img>
        <v-icon v-else @click="$refs.refInputEl.click()">{{ icons.mdiUpload }}</v-icon>
      </v-avatar>

      <!-- upload photo -->
      <div>
        <v-btn color="primary" class="me-3 mt-5" @click="$refs.refInputEl.click()">
          <v-icon class="d-sm-none">
            {{ icons.mdiCloudUploadOutline }}
          </v-icon>
          <span class="d-none d-sm-block">Subir logo</span>
        </v-btn>

        <input v-on:change="newImageUpload" ref="refInputEl" type="file" accept="image/jpeg,image/png" :hidden="true" />

        <v-btn color="error" outlined class="mt-5" @click="removeImage">
          Eliminar
        </v-btn>
        <p class="mb-1 error--text" v-show="inputs.image.errors.length > 0">
          <span v-for="(e, key) in inputs.image.errors" :key="key">{{ e }}</span>
        </p>
        <p class="text-sm mt-5">
          Formatos permitidos: JPG o PNG. Tamaño máximo de 10MB
        </p>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col md="6" cols="12">
            <v-text-field
              v-model="inputs.name.value"
              :error="inputs.name.errors.length > 0"
              :messages="inputs.name.errors"
              label="Nombre"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col md="6" cols="12">
            <v-text-field
              v-model="inputs.company.value"
              :error="inputs.company.errors.length > 0"
              :messages="inputs.company.errors"
              dense
              outlined
              disabled
              label="Empresa"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="inputs.last_name.value"
              :error="inputs.last_name.errors.length > 0"
              :messages="inputs.last_name.errors"
              dense
              label="Apellido"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="inputs.email.value"
              :error="inputs.email.errors.length > 0"
              :messages="inputs.email.errors"
              label="Correo electrónico"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" class="me-3 mt-4" @click="save" :loading="loading">
              Guardar cambios
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiUpload, mdiCloudUploadOutline } from '@mdi/js'
import helpers from '../../mixins/helpers'

export default {
  mixins: [helpers],
  mounted() {
    const user = this.getUser

    this.inputs.name.value = user.name
    this.inputs.last_name.value = user.last_name
    this.inputs.email.value = user.email
    this.inputs.company.value = user.company

    this.currentImage = user.img_company ? this.storagePrivateLinkApi(user.img_company) : null
  },
  data() {
    return {
      inputs: {
        name: {
          value: '',
          errors: [],
        },
        last_name: {
          value: '',
          errors: [],
        },
        company: {
          value: '',
          errors: [],
        },
        email: {
          value: '',
          errors: [],
        },
        image: {
          value: null,
          errors: [],
        },
        delete_image: {
          value: false,
          errors: [],
        },
      },
      currentImage: null,
      icons: {
        mdiUpload,
        mdiCloudUploadOutline,
      },
      loading: false
    }
  },
  methods: {
    newImageUpload(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.inputs.delete_image.value = false
      this.inputs.image.value = files[0]
      this.currentImage = URL.createObjectURL(files[0])
    },
    removeImage() {
      this.inputs.delete_image.value = true
      this.inputs.image.value = null
      this.currentImage = null
    },
    save() {
      this.loading = true;
      this.trimInputsText()
      this.resetErrors()
      let url = `${this.$base_api_url}/updateUserData`

      let data = {
        name: this.inputs.name.value,
        last_name: this.inputs.last_name.value,
        email: this.inputs.email.value,
        image: this.inputs.image.value,
      }

      let form = new FormData()

      this.$_.forEach(data, (v, key) => {
        form.append(key, v == null ? '' : v)
      })

      form.append('delete_image', this.inputs.delete_image.value ? 1 : 0)

      this.$http
        .post(url, form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          this.syncUserData().then(() => {
            this.loading = false;
            this.$swal.fire({
              position: 'top-end',
              text: `Se actualizo la información correctamente`,
              icon: `success`,
              showConfirmButton: false,
              timer: 3000,
              backdrop: false,
              showClass:{
                popup: this.isDark()?'theme-dark':''
              }
            });
          });
        })
        .catch(res => {
          this.catchResponse(res)
          this.loading = false;
        })
    },
    syncUserData() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('getUser').then(() => {
          resolve(true);
        }).catch(res=>{
          reject(res)
        })
      })
    },
  },
  computed: {
    getUser() {
      return this.$store.getters.getUser
    },
  },
}
</script>
