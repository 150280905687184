<template>
  <v-card flat class="mt-5">
    <v-form>
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" sm="8" md="6">
              <!-- current password -->
              <v-text-field
                v-model="inputs.current_password.value"
                :error="inputs.current_password.errors.length > 0"
                :messages="inputs.current_password.errors"
                :type="isCurrentPasswordVisible ? 'text' : 'password'"
                :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                label="Contraseña actual"
                outlined
                dense
                @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
                :class="{'mb-2': inputs.current_password.errors.length > 0}"
              ></v-text-field>

              <!-- new password -->
              <v-text-field
                v-model="inputs.password.value"
                :error="inputs.password.errors.length > 0"
                :messages="inputs.password.errors"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                label="Nueva contraseña"
                outlined
                dense
                hint="Debe contener 8 caracteres, una letra minuscúla, una letra mayúscula y un simbolo"
                persistent-hint
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>

              <!-- confirm password -->
              <v-text-field
                v-model="inputs.password_confirmation.value"
                :error="inputs.password_confirmation.errors.length > 0"
                :messages="inputs.password_confirmation.errors"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                label="Confirma nueva contraseña"
                outlined
                dense
                class="mt-3"
                @click:append="isCPasswordVisible = !isCPasswordVisible"
              ></v-text-field>
              <div>
                <!-- action buttons -->
                <v-btn color="primary" class="mt-3" @click="save">
                  Guardar cambios
                </v-btn>
              </div>
            </v-col>

            <v-col cols="12" sm="4" md="6" class=" mb-4 d-none d-sm-flex justify-center position-relative">
              <v-img
                contain
                min-width="500"
                src="@/assets/images/3d-characters/seguridad.svg"
                class="security-character"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import helpers from '../../mixins/helpers'

export default {
  mixins: [helpers],
  data() {
    return {
      isCurrentPasswordVisible: false,
      isNewPasswordVisible: false,
      isCPasswordVisible: false,
      inputs: {
        current_password: {
          value: '',
          errors: [],
        },
        password: {
          value: '',
          errors: [],
        },
        password_confirmation: {
          value: '',
          errors: [],
        },
      },
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
  methods: {
    save() {
      this.resetErrors()
      this.loading = true
      const url = `${this.$base_api_url}/updatePassword`

      let data = {
        current_password: this.inputs.current_password.value,
        password: this.inputs.password.value,
        password_confirmation: this.inputs.password_confirmation.value,
      }

      this.$http
        .post(url, data)
        .then(() => {
          this.resetInputs();
          this.$swal.fire({
            position: 'top-end',
            text: `Se actualizo tu contraseña correctamente`,
            icon: `success`,
            showConfirmButton: false,
            timer: 3000,
            backdrop: false,
            showClass: {
              popup: this.isDark() ? 'theme-dark' : '',
            },
          })
        })
        .catch(res => {
          this.catchResponse(res);
        }).then(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
@media (max-width: 1150px) {
  .security-character {
    min-width: 400px !important;
    margin-bottom: 50px !important;
  }
}
@media (max-width: 950px) {
  .security-character {
    display: none !important;
  }
}
</style>
